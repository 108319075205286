@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~quill/dist/quill.snow.css';
@import "./style/variableProvider.less";

/* START BESTELLFORMULAR-APP LIBRARY STYLING */
.webapps {
	.libPreserveWhitespace {	
		white-space: pre-line;
	}

	.bestellformularContainer {
		*:not(mat-icon){
			font-family: @DefaultFont;
		}

		.appname {
			margin-left: 16px;
			margin-top: 16px;
		}

		.lib-campaign-description {
			font-size: 14px;
		}	

		mat-card-actions.lib-button-container {
			padding: 0 8px !important;
		}

		mat-card.lib-package-card, mat-card.lib-campaign-card{
			mat-card-subtitle {
				div.lib-status-indicator {
					mat-chip-list {
						.mat-chip, .libServiceChip{
							color: @PrimaryCiColor !important;
							background-color: @LightGrey !important;
							border: 1px solid @PrimaryCiColor !important;
						}
						.mat-chip:hover::after {
							color: @PrimaryCiColor !important;
							cursor: default;
							opacity: 0;
						}
					}
				}
			}
			mat-card-content {
				font-size: 14px;
			}
		}

		h1.mat-h1 {
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			color: @PrimaryFontColor;		
		}
		
		h2.mat-h2 {
			font-weight: 500;
			font-size: 24px;
			line-height: 32px;		
			color: @PrimaryFontColor;
		}

		a {
			border-bottom: none;
			display: unset;
			color: @HighlightColor
		}

		input {
			height: unset;
			font-size: unset;
			padding: unset;
			border: none !important;
			background: none;
		}

		input:disabled {
			background: none;
			border: none !important;

			&:hover {
				background: none;
			}
		} 

		button {
			text-transform: uppercase;
		}

		button.mat-raised-button {
			background-color: @HighlightColor;
			color: @SecondaryFontColor;
		}
		button.mat-stroked-button {
			border-color: @PrimaryCiColor;
			color: @PrimaryCiColor;
		}

		button.mat-button {
			color: @HighlightColor;
		}

		.mat-step-icon-selected, .mat-step-icon-state-done, .mat-step-icon-state-edit {
			background-color: @HighlightColor;
		}
	}	
}
/* END BESTELLFORMULAR-APP LIBRARY STYLING */

/* START NEWS-APP LIBRARY STYLING */
.webapps {
	div.libTitleInputContainer {
		.mat-focused .mat-form-field-label {
			color: @SecondaryCiColor;
		}
		
		.mat-focused div.mat-form-field-outline-thick {
			color: @SecondaryCiColor ;
		}
	}
	
	h2.mat-h2 {
		font-weight: 500;
	}

	h3.libNewsHeadline {
		font-size: 14px;
		font-weight: bold;
		margin-top: 16px;
	}

	button.libNewsButton {
		color: black;
		margin-right: 2rem;
		
		span.mat-badge-content.mat-badge-active {
			background-color: @SecondaryCiColor;
			top: 0;
			width: 20px;
			height: 20px;
			font-size: 12px;
			line-height: 20px;
		}
	}
	div.lbAddNewsButton > svg {
		fill:@SecondaryCiColor;
	}
	div.libNewsButtons svg {
		fill:@SecondaryCiColor;
	}
	h3.libNewsHeadline > svg {
		fill:@SecondaryCiColor
	}
}
/* END NEWS-APP LIBRARY STYLING */


/* START BESTELLUNGEN LIBRARY STYLING */

.webapps{
	button.export-button, button.libSaveBeschreibungstextButton {
		background-color:@SecondaryCiColor;
		color: white
	}
	mat-chip.alle-loeschen{
		background-color: @SecondaryCiColor;
	}

	table.libTabelle {
		width: unset;
	}
	
	th.libTabelleHeader {
		background-color: @SecondaryCiColor;
	}
	
	h3.contentHeader {
		width: 312px;
		font-weight: bold;
	}	

	div.libSelected {
		background-color: @HighlightColor;
	}	

	div.libSelected > div.content {
		h3.contentHeader {
			color: @SecondaryFontColor;
		}		
	}

	div.detailContainer  p {
		line-height: 1.1rem;
	}

	div.libButtons {
		button.weiterButton {
			background-color: @SecondaryCiColor;
		}
	}	
	
	div.libPanelContent.panelOpened {
		border-color: @SecondaryCiColor
	}

	div.libUserViewContainer {
		display: block;
		padding: 0 0.75rem 0.75rem 0.75rem;
		
		.libTable > div.libColumn.singleColumn {
			min-width: unset;
			width: 100%;
		}

		.libTable {
			width: unset;
		}

		h3.libColumnHeader {
			font-size:16px;
			font-weight: bold;
		}

		div.libColumn:first-child {
			width: 40%;
	
			div.left {
				width: 100%;
			}
		}
	
		div.libColumn:last-child {
			width: calc(60% - 0.125rem);
	
			div.right {
				width: 100%;
			}
		}
	}

}

/* END BESTELLUNGEN LIBRARY STYLING */


/* START LOKALE GOOGLE WERBUNG LIBRARY STYLING */
.webapps {
	
	.libDivider {
		border-color: @SecondaryCiColor;	
	}

	button.libRaisedButton {
		color: white;
		background-color: @SecondaryCiColor;			
	}

	button.libRaisedButton[disabled] {
		color: white;
		background-color: @ButtonInactiveColor;
	}

	mat-card.libKampagnenCard {
		img.libKampagnenBild {
			max-width: unset;
		}
		mat-card-header {
			mat-card-title {
				h1.libKampagnenName {
					color: @PrimaryCiColor !important
				}
			}
			mat-card-subtitle {
				div.libStatusIndikator {
					mat-chip-list {
						.mat-chip{
							color: @PrimaryCiColor !important;
							background-color: @LightGrey !important;
							border: 1px solid @PrimaryCiColor !important;
							.mat-icon {
								color: @PrimaryCiColor !important;
							}
						}
						.mat-chip:hover::after {
								color: @PrimaryCiColor !important;
								cursor: default;
								opacity: 0;
							}
						}
					}
				}
		}
		mat-card-content {
			div.libKampagnenHinweisTitel {
				color: @ErrorColor !important;
				span {
					color: @ErrorColor !important;
				}
				mat-icon {
					color: @ErrorColor !important;
				}
			}
			.libHinweisTooltip {
				background-color: var(--color--feedback--error--alpha-80) !important;
			}
		}
		mat-card-actions {
			button.libBuchenButton {
				background-color: @PrimaryCiColor !important;
				color: @White !important;
			}
			button.libAnsehenButton {
				color: @PrimaryCiColor !important;
				border-color: @PrimaryCiColor !important;
			}
		}
	}

	mat-dialog-actions {
		button.libButton {
			color: @White !important;
			background-color: @PrimaryCiColor !important;
		}
	}

	mat-card.libPaketCard {
		
		.libRibbon {		
			p {
				background: @SecondaryCiColor !important;
			}
		
			p:before {
				border: 3px solid #00b1eb8f !important;
				border-right-color: transparent !important;
				border-bottom-color: transparent !important;
			}
		
			p:after {
				border: 3px solid #00b1eb8f !important;
				border-bottom-color: transparent !important;
				border-left-color: transparent !important;
			}
		}

		.libPaketCardTitle {
			color: @PrimaryCiColor !important;
		}

		.libPaketCardFooter {
			.libPaketCardPrice {
				color: @PrimaryCiColor !important;
			}
			
			button.libPaketAuswahlButton  {
				background-color: @PrimaryCiColor;
			}
		
			button.libPaketAuswahlButton:disabled {
				background-color: @ButtonInactiveColor;
			}
		}
	}

	mat-dialog-container {
		button.libRaisedButton {
			background-color: @SecondaryCiColor;
		}

		div.libSvgRow {
			.libSvgRowItem {
				color: @SecondaryCiColor;

				.libSvgContainer {
					border-color: @SecondaryCiColor;
					
					.libSvgSmall {
						path {
							fill: @SecondaryCiColor; 
						}
					}
					.libSvg {
						path {
							fill: @SecondaryCiColor; 
						}
					}
				}
			}
		}
	}

	div.libPaketExpansionFooter{
		.libMarkerBottom{
			border-top-color: @SecondaryCiColor;
		}	
	}

	div.libButtonContainer{
		button.libKampagneAnlegen, button.libKampagneBearbeiten {
			background-color: @PrimaryCiColor;
		}

		button.libWeiterButton:disabled {
			background-color: @ButtonInactiveColor;
		}	

		.libWeiterButton {
			background-color: @PrimaryCiColor;
			color: white;
		}

		.libZurueckButton {
			color: @PrimaryCiColor;
			border: 1px solid @PrimaryCiColor;
		}	
	}

	div.libPaketContainer {
		.libAddSvg {
			fill: @SecondaryCiColor;
		}
	}

	div.libSepaDownloadButton {
		.libDownloadSvg {
			fill: @SecondaryCiColor !important;	
		}
		.libDownloadText {
			color: @SecondaryCiColor;
		}
	}

	div.libMarkerTop{
		border-top-color: @SecondaryCiColor;
	}

	div.libDatenfeldContainer {
		.libDatenfeld {
			.mat-focused .mat-form-field-label {
				color: @SecondaryCiColor;
			}
			.mat-focused .mat-form-field-underline .mat-form-field-ripple {
				background-color: @SecondaryCiColor;
			}
			.mat-form-field-disabled > div > div > div > input, textarea {
				background: none;
				border: none;
			}
		}

		div.libAddKampagneDatenfeld {
			.libAddSvg {
				fill: @SecondaryCiColor;
			}
		}		
	}

	div.libDataContainer {
		div.libBankverbindung, div.libRechnungsadresse {
			.mat-focused .mat-form-field-label {
				color: @SecondaryCiColor;
			}
			.mat-focused .mat-form-field-underline .mat-form-field-ripple {
				background-color: @SecondaryCiColor;
			}
			.mat-form-field-disabled > div > div > div > input, textarea {
				background: none;
				border: none;
			}
		}					
	}

	mat-expansion-panel {
		div.libKampagneDatenContainer {
				.mat-focused .mat-form-field-label {
					color: @SecondaryCiColor;
				}

				.mat-focused .mat-form-field-outline-thick {
					color: @SecondaryCiColor !important;
				} 
		}

		div.libGoogleKampagne {
			.mat-focused .mat-form-field-outline-thick {
				color: @SecondaryCiColor;
			} 

			.mat-focused .mat-form-field-infix {
				.mat-form-field-label-wrapper {
					.mat-form-field-label {
						color: @SecondaryCiColor;
					}
				}
			}
		}

		button.UploadButton{
			background-color: @SecondaryCiColor;
		}		

		div.libPaketContainer   {
				.mat-focused .mat-form-field-label {
					color: @SecondaryCiColor;
				}

				.mat-focused .mat-form-field-outline-thick {
					color: @SecondaryCiColor !important;
				} 

				.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate  {
					background-color: @SecondaryCiColor !important;
				}

				.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
					background-color: @SecondaryCiColor !important;
				}	
		}		
	}


	form.libDatenfeldForm   {
			.mat-focused .mat-form-field-label {
				color: @SecondaryCiColor;
			}

			.mat-focused .mat-form-field-underline .mat-form-field-ripple {
				background-color: @SecondaryCiColor;
			} 
	}

	div.libCheckbox {
		.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate  {
			background-color: @SecondaryCiColor;
		}

		.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
			background-color: @SecondaryCiColor;
		}
	}

	div.libPaketContainer {
		div.libPaketFlex {
			button.libCopyButton {
				background-color: @SecondaryCiColor;		
			}
		}	
	}

	.libDatenfeldCard {
		.libDatenfeldForm {
			.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate  {
				background-color: @SecondaryCiColor;
			}

			.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element {
				background-color: @SecondaryCiColor;
			}	

			.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
				border-color: @SecondaryCiColor; 
			}
			
			.mat-radio-button.mat-accent .mat-radio-inner-circle{
				color:@SecondaryCiColor;
				background-color: @SecondaryCiColor;
			}
		}
	}

	button.libSubmitButton:enabled {
		background-color: @SecondaryCiColor ;
		color: @White; 
	}

	.libMatFormField {
		&.error {
			background-color: white;
		}

		table.libPreisHinweisContainer {
			margin-top: 0.75rem;
		}

		table.libPreisHinweisContainer > tbody td {
			border: none;
		}

	}

	.libTagesbudgetFaktor {
		.mat-form-field-suffix {
			div {
				margin-top: 0.75rem;
			}
		}

		.mat-form-field-flex {
			align-items: center;
		}
	}

	mat-spinner.mat-mdc-progress-spinner circle, mat-spinner.mdc-circular-progress, mat-spinner.mat-spinner circle {
		stroke: @SecondaryCiColor;
	}

	.libTd {
		padding: 4px 0;
		border: none;
	}

	.libMatStepper {
		.mat-step-header .mat-step-icon-selected {
			 background-color: @PrimaryCiColor !important; 
		 }
		.mat-step-header .mat-step-icon-state-done {
			background-color: @PrimaryCiColor !important; 
		}
		.mat-step-header .mat-step-icon-state-edit {
			background-color: @PrimaryCiColor !important; 
		}
		.mat-horizontal-content-container {
			overflow: hidden !important;
		}
	}

	.mat-datepicker-popup {
		mat-datepicker-content {
			mat-calendar {
				.mat-calendar-content {
					.mat-calendar-table {
						thead th {
							border: none;
						}

						tbody td {
							div.mat-calendar-body-selected {
								background-color: @SecondaryCiColor !important;
							}
						}
					}
				}
			}
		}
	}

	.libSliderContainer {	
		div.mat-slider-track-fill {
			background-color: @SecondaryCiColor;
		}		
		div.mat-slider-thumb {
			background-color: @White; 
			
			div.emojiThumb {
				background-color: @White; 
			}
		}		
	}

	mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background-color: @SecondaryCiColor;
	}
	
	div.libFeedbackInput, div.libSonstigesCheckbox {
		.mat-focused div.mat-form-field-outline-thick {
			color: @SecondaryCiColor;
		}
	}

	.libBeschreibungsContainer, .libKampagneContent, .libPaketBeschreibung {
		a, .defaultLink, .dummyLink {
			border-bottom: 0;
			text-decoration: underline;
		}	
	}
	
	div.libKampagneRibbon > .wrapper {
		background-color: @SecondaryCiColor !important;
	}

	div.libKampagneRibbon span::before{
		border-left: 3px solid #00b1eb8f !important;
		border-bottom: 3px solid #00b1eb8f !important;
	}
	
	div.libKampagneRibbon::after{
		border-left: 3px solid #00b1eb8f !important;
	}
	
	.libAdminButton {
		background-color: @PrimaryCiColor !important;
	}

	.mat-focused .mat-form-field-underline .mat-form-field-ripple {
		background-color: @PrimaryCiColor !important;
	} 
	
	.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
		color: @PrimaryCiColor !important;
	}
	
	.mat-form-field.mat-focused .mat-form-field-label {
		color: @PrimaryCiColor !important;
	}
	
	.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick {
		color: red !important;
	}
	
	.mat-form-field.mat-form-field-invalid .mat-form-field-label {
		color: red !important;
	}
	
	.loadingScreen {
		.mat-mdc-progress-spinner circle, .mdc-circular-progress, .mat-spinner circle {
			stroke: @PrimaryCiColor !important;
		}
	}
}

/* END LOKALE GOOGLE WERBUNG LIBRARY STYLING */

.cdk-global-scrollblock bas-root{
	position: relative;
	z-index: 0;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.7);
}

.cdk-overlay-container{
	z-index: @Index_OverlayContainer;
}

.cdk-overlay-pane {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;


	mat-dialog-container {
		grid-column: 1;
		grid-row: 1;
		max-height: none;
	}

	.mat-dialog-actions {
		margin-bottom: -22px;
	}
}


.mat-dialog-title {
	font-family: inherit;
}

svg.no-transition:not(:hover) {
	.svg-transition {
		display: none;
	}
}
svg.no-transition:hover {
	.svg-hide-transition {
		display: none;
	}
}
svg:not(.no-transition):not(:hover) {
	.svg-transition {
		fill: transparent;
	}
}

//Das Drecksteil von IE kann das nicht. Pech gehabt.
//Das Team hat entschieden, dass IE-NutzerInnen keine sticky Images brauchen.
.stickyImage{
	position: sticky;
	top: 0;
}
