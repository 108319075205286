@import "../utilities/lib.less";

.hasErrors, .hasWarnings {
	position: relative;

	&:not(.panel):not([class*="cols"]):has(> [class*="cols"]),
	&:not(#editMaskContainer *) {
		width: fit-content;
	}
}
